<template>
  <div>
    <b-modal size="xl" hide-footer v-model="modalReservations" class="w-100" title="Detalle de Reserva">
      <b-row>
          <b-col md="2">
            <b-form-group label="Nro Reserva:">
              <b-form-input type="text" disabled v-model="reservation.code"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Cliente:">
              <b-form-input type="text" disabled v-model="reservation.client"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Personas:">
              <b-form-input type="text" disabled v-model="reservation.persons"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Hora:">
              <b-form-input type="text" disabled v-model="reservation.hour"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Observación:">
              <b-form-textarea rows="15" disabled v-model="reservation.observation"></b-form-textarea>
            </b-form-group>
          </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<style>



</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";
export default {
  name: "ModalsProduct",
  data() {
    return {
        modalReservations:false,
        module: 'Order',
        perPage: 15,
        currentPage: 1,
        rows: 0,
        data_table: [],
        role:2,
        id_reservation:'',
        search:'',
        reservation:{
          code:'',
          client:'',
          date:'',
          hour:'',
          persons:'',
          observation:'',
        }
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalDetailReservationShow', (data) => {
      this.modalReservations = true;
      this.role = data.role;
      this.id_reservation = data.id_reservation;
      this.ViewReservation();
    });
    
  },
  methods: {
      ViewReservation,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ViewReservation() {
  let me = this;
  let url = me.url_base + "reservation/view/"+ this.id_reservation;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.reservation.code = response.data.result.code;
      me.reservation.client = response.data.result.client_name+" - "+response.data.result.client_document_number;
      me.reservation.date = response.data.result.date;
      me.reservation.persons = response.data.result.persons;
      me.reservation.hour = response.data.result.hour;
      me.reservation.observation = response.data.result.observation;
      
    }
    me.isLoading = false;
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    me.isLoading = false;
  });
}

</script>
